@import "_variables";
.quiz-board {
  min-height: 100vh;
  background-color: $background-color;
  margin-top: 57px;
  text-align: left;
  .intro-part{
    background-color: white;
    font-family: 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    p{
      color: rgb(170, 170, 226);
    }
  }
  .quiz-main-section {
    display: flex;
    justify-content: center;
  
    .task-div-section {
      display: flex;
      gap: 1rem;
      padding: 1rem;
    }
  
    .task-div {
      border: 1px solid $border-color;
      background-color: #ffffff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Card shadow for depth
      border-radius: 8px; // Rounded corners for the card
      overflow: hidden; // Clips overflow for a clean look
      transition: transform 0.3s ease, box-shadow 0.3s ease; // Smooth hover effect
      cursor: pointer;
      min-height: 250px;
      padding: 12px;
      &:hover {
        transform: translateY(-5px); // Lifts the card on hover
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); // Adds a stronger shadow on hover
      }
  
      .img-div {
        background-color: rgba(224, 224, 230, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        height: 190px; 
  
        img {
          object-fit: cover;
          max-width: 100%; // Restrict image size within container
          max-height: 100%;
        }
      }
  
      .text-section {
        padding: 1rem;
        text-align: left;
  
        h6 {
          color: $greyDark;
          margin-bottom: 0.5rem;
        }
  
        a {
          color: $greyDark;
          font-weight: bold;
          text-decoration: none;
  
          &:hover {
            text-decoration: underline;
          }
        }
  
        span {
          font-size: 0.9rem;
          color: #666; // Subtle color for descriptive text
        }
      }
    }
  }
  
}

/* Extra Small Devices, Phones */

@media (max-width: 576px) {
  .task-div-section {
    flex-direction: column;
    row-gap: 20px;
  }
}
