@import "_variables";

.custom-table {
  background-color: $lightcolor;
  border: 1px solid $border-color;
  text-align: left;
  .table-header {
    border: 1px solid $border-color;
    padding:12px;
    i {
      font-size: large;
      font-weight: 500;
      color: blue;
    }
    #accessSelect {
      border: none;
      color: blue;
      &:hover {
        border: none;
        color: blue;
      }
    }
  }
  .header-row {
    td {
      border: 1px solid $border-color;
      color: $grey;
    }
    .select-box,
    input {
      border: none;
      color: $grey;
    }
  }
  tr{
    border:1px solid $border-color;
    th{
      font-size: 18px;
      padding:12px 10px;
    }
    td{
      padding:5px 10px;
      font-size: 16px;
    }
  }
}
