@import "_variables";

.question-list , .admins-list{
    table{
      border:1px solid $border-color;
      text-align: justify;
      cursor: pointer;
      tr{
        background-color: $lightcolor;
        th{
          font-weight: 400;
        }
        td{
          .custom-select{
            width:40%;
            border:none;
            &:hover{
              border:none;
              box-shadow: none;
            }
          }
        }
        a{
          color:$greyDark;
        }
      }
      thead tr{
        background-color:$border-color ;
      }
    }
}