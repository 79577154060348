.Assessment-test {
  font-family: "";
  left: 0;
  right: 0%;
  top: 0%;
}
$btn-color: rgb(51, 108, 199);
$btn-font-color: white;

.assessment-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;

  .border {
    flex-grow: 1;
  }
}

// TestStart section
.TestStart-Section {
  .custom-marker {
    background-color: #cfe2ff;
  }
  .TestStart-card {
    box-shadow: 0 0 10px rgba(165, 165, 165, 0.5);
    // max-width: 50%;
    .icon {
      font-size: 65px;
    }
  }
  .text-start-btn {
    padding: 5px 85px;
    background-color: $btn-color !important;
    color: $btn-font-color !important;
  }
}

// assessment-section
.Assessment-header {
  border: 1px solid rgb(234, 234, 235);
  // box-shadow: 0 4px 6px rgba(165, 165, 165, 0.5);
}
.option-block {
  border: 1px solid grey;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    border-color: #007bff;
    box-shadow: 0 1px 1px #007bff;
  }

  &.border-primary {
    border-color: #007bff;
  }

  .form-check-label {
    color: inherit;
  }
}
.report-para {
  font-size: 13px !important;
}

// assessment-timer section
.assessment-timer {
  p {
    background-color: #cfe2ff;
  }
}

//asssessment-report scss
// .assessment-report {
//   .percentile-bar {
//     width: 60%;
//     height: 22px;
//     background-color: #e0e0e0;
//     border-radius: 50px;
//     position: relative;
//     overflow: hidden;
//     display: flex;
//     align-items: center;

//     .percentile-fill {
//       height: 100%;
//       background-color: #007bff; /* Fill color */
//       border-radius: 50px;
//       transition: width 0.5s ease-in-out;
//     }
//   }
// }
.assessment-report {
  .report-container {
    padding: 3rem 2rem;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;

    // Icon styling
    .report-icon {
      font-size: 4rem;
      position: absolute;
      top: -48px;
      left: 50%;
      transform: translateX(-50%);
    }

    .report-heading {
      margin-bottom: 1rem;
      font-weight: bold;
    }

    .report-score {
      font-size: 1.25rem;
      color: #333;

      .score-value {
        font-weight: bold;
      }
    }

    .performance-title {
      color: #83b6e2;
      margin-bottom: 1rem;
    }

    .progress-bar-wrapper {
      width: 75%;
      background-color: #e9ecef;
      border-radius: 50px;
      height: 22px;
      overflow: hidden;

      .progress-bar-fill {
        height: 100%;
        text-align: center;
        color: white;
        font-weight: bold;
        border-radius: 50px;
        transition: width 0.4s ease;
      }
    }

    .performance-stats {
      margin-top: 1.5rem;
      color: #6c757d;

      .highlight {
        font-weight: bold;
      }
    }

    .footer-note {
      margin-top: 0.5rem;
      color: #6c757d;
    }
  }
}

// Certificated of assesment
.certificate-container {
  max-width: 90%; /* Use a percentage-based width for responsiveness */
  max-height: 90vh; /* Ensure it doesn't exceed the viewport height */
  width: 800px; /* Default width for larger screens */
  height: auto; /* Allow height to scale based on content */
  aspect-ratio: 4 / 3; /* Maintain a consistent aspect ratio */
  border: 2px solid #000;
  border-radius: 10px;
  text-align: center;
  padding: 30px;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Georgia", serif;
  margin: 0 auto; /* Center the certificate horizontally */

  h1 {
    font-family: "Times New Roman", serif;
    color: #333;
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
    margin-top: 15px;
    color: #555;
  }

  h2 {
    color: #007bff;
    margin-top: 10px;
    font-size: 1.5rem;
    font-weight: bold;
  }

  h3 {
    color: #28a745;
    margin-top: 10px;
    font-size: 1.3rem;
    font-weight: bold;
  }

  h4 {
    font-size: 1.2rem;
    margin-top: 20px;
    font-weight: bold;

    &.pass {
      color: #28a745;
    }

    &.fail {
      color: #dc3545;
    }
  }

  .signature {
    font-size: 0.9rem;
    margin-top: 40px;
    color: #555;
  }

  hr {
    width: 80%;
    margin: 20px auto;
    border-color: #ccc;
  }

  .company-name {
    font-size: 0.9rem;
    margin-top: 10px;
    color: #999;

    strong {
      font-weight: bold;
    }
  }
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .certificate-container {
    padding: 20px;
    width: 100%; /* Use full width on small devices */
    font-size: 0.9rem; /* Slightly reduce font size for better fit */
  }

  .certificate-container h1 {
    font-size: 1.5rem;
  }

  .certificate-container h2,
  .certificate-container h3,
  .certificate-container h4 {
    font-size: 1.1rem;
  }

  .certificate-container p {
    font-size: 0.9rem;
  }
}

/* Media query for very small screens */
@media (max-width: 480px) {
  .certificate-container {
    padding: 15px;
    font-size: 0.8rem;
  }

  .certificate-container h1 {
    font-size: 1.3rem;
  }

  .certificate-container h2,
  .certificate-container h3,
  .certificate-container h4 {
    font-size: 1rem;
  }

  .certificate-container p {
    font-size: 0.8rem;
  }
}

// mobile responsive

@media (max-width: 500px) {
  .TestStart-Section {
    .TestStart-card {
      // max-width: 75%;
    }
  }
}
