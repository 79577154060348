$btn-color: rgb(51, 108, 199);
$btn-font-color: white;

.btnn-btnn {
  b {
    padding: 0px 65px;
    letter-spacing: 1px;
  }
}

// Assessment-body section

.Assessment-body {
  top: 0;
  right: 0;
  overflow-y: hidden;
}
