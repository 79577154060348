$font-color-assessment: #0d6efd;

// .search-container{
//   position: relative;
//   display: inline-block;
// }

// .search-input {
//   padding: 10px;
//   padding-left: 40px; /* Add extra space to the left for the icon */
//   border: 1px solid rgb(51, 108, 199) !important;
//   font-size: 16px;
//   width: 200%;
//   :hover{
//     border: 1px solid rgb(51, 108, 199) !important;
//   }
// }

// .search-icon {
//   position: absolute;
//   top: 50%;
//   left: 15rem;
//   transform: translateY(-50%);
//   color: $font-color-assessment;
// }
.AssessmentTestSkillHeader-A{
  span{
    color:$font-color-assessment ;
  }
 .assessment-p-1{
  color:$font-color-assessment ;
 }
 h4{
  color: aliceblue;
  background-color:$font-color-assessment ;
 }
}

// Assessment-card-manage

.Assessment-card-manage{
  width: 50%;
 .card{
    border:  1px solid rgba(214, 207, 207, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    background-color:rgba(193, 193, 193, 0.089);
  }
  
}

//Assessment-cards-section

.Assessment-cards-section{
 background-color:rgba(167, 167, 167, 0.1);
 min-height: 100vh;
 .assessment-card-A{
  background-color: white;
  border: 1px solid white;
  box-shadow: 0 0 10px rgba(155, 157, 165, 0.1); 
 
 }
}

// welcome-section

.welcome{
.welcome-section{
  .welcome-section-A{
    min-height: 91vh;
    background-color: rgb(241, 241, 255);
  }
}
}

@media (max-width: 480px) { // Mobile screens
  .search-icon {
    left: 15rem; 
    font-size: small;// Adjust the icon position
  }
  
  .search-input {
    padding-left: 30px; // Adjust padding for mobile view
    font-size: 14px; 
    width: 150%;// Adjust font size for smaller screens
  }
}

// Assessment-card-manage

@media (max-width: 480px) {
  .Assessment-card-manage{
    width: 100% !important;
    .card{
      box-shadow: 0 0 4px rgba(155, 157, 165, 0.1); 
      padding-top: 10px;
    }
  }


// welcome-section 
.welcome{
  .welcome-section{
    .welcome-section-A{
      min-height: 50vh;
      background-color: rgb(241, 241, 255);
    }
  }
  }
}
