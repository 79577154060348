// @import "_variables";

$bg-color: rgb(0, 31, 82);
$color: rgb(0, 31, 82);
$border-color: 1px solid rgb(151, 180, 227);

// header file code

.PlayboardHeader-section {
  background: #fff;
  min-height: 8vh;
  color: rgb(255, 255, 255);
}
.PlayboardHeader-col-2-A {
  font-size: 13px;
}
.PlayboardHeader-col-3-A {
  font-size: 14px;
  button {
    background-color: #6767e0;
    color: #fff;
    font-weight: 900;
    padding: 10px 20px;
    border: 2px solid #6767e0;
    &:hover {
      background-color: #fff;
      color: #6767e0;
      border-color: #6767e0;
      border-width: 2px;
    }
  }
}
.PlayboardHeader-col-2-A small {
  color: rgb(205, 205, 205);
}

// QuizBody-footer section start

.footer_section {
  color: $color;
  font-size: 11px;
  letter-spacing: 0.1px;
}

// QuizQuestion section start

section {
  .QuizQuestion {
    font-size: 13px;
    font-weight: 600;
    color: $color;
    cursor: pointer;
  }
  .QuizQuestionData {
    a {
      font-size: 13px;
      font-weight: 600;
      color: $color;
      cursor: pointer;
    }
    span {
      font-size: 13px;
    }
  }
  p {
    font-size: small;
    color: $color;
  }
}

//  QuizBodyAnswer section start

section {
  .QuizAnswerHead {
    font-size: 13px;
    font-weight: 600;
    color: $color;
    cursor: pointer;
  }
  .QuizAnswerHead-2 {
    a {
      font-weight: 600;
      font-size: 13px;
      color: $color;
      cursor: pointer !important;
    }
    span {
      font-size: 13px;
      cursor: pointer;
      font-weight: 600;
      color: $color;
    }
  }
  .QuizAnswer-option {
    text-align: left;
    border: 1px solid rgb(151, 180, 227);
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 0 10px rgba(0, 123, 255, 0.3); /* Light blue shadow */
    }

    input {
      border: 1px solid rgb(151, 180, 227);
      font-size: small;
      cursor: pointer;
    }

    label {
      padding-left: 10px;
      font-size: small;
      color: $color;
    }
  }
}

// .option  input[type="radio"]{
//   margin-right: 10px;
// }

// QuizBodySection start

section {
  .QuizBodySection {
    background-color: rgb(255, 255, 255);
    // margin-top: -30px;
  }
}
// QuizBodyHeader-section start

section {
  .QuizBodyHeader-section {
    .QuizBodyHeader-col-1 {
      select {
        font-size: small;
        border: 1px solid rgb(151, 180, 227);
        color: $color;
      }
      .icon-section {
        border: 1px solid rgb(151, 180, 227);
        font-size: small;
        color: $color;
      }
    }
    .QuizBodyHeader-col-2 {
      a {
        border: 1px solid rgb(151, 180, 227);
        color: $color;

        span {
          color: $color;
        }
      }
      .QuizBodyHeader-col-2-A {
        border: 1px solid rgb(151, 180, 227);
        color: $color;
      }
    }
    .QuizBodyHeader-col-3 {
      .QuizBodyHeader-col-3-A {
        button {
          font-size: small;
        }
      }
    }
  }
}

// QuizBody
section {
  .QuizBodySection {
    .QuizBody {
      .QuizBody-Row {
        // border-top: 1px solid rgb(151, 180, 227);
        // border-bottom: 1px solid rgb(151, 180, 227);
      }
      // .QuizBody-col-2{
      //   border-left: 1px solid rgb(151, 180, 227);
      // }
    }
  }
}

.green-color {
  background-color: green !important;
  color: white !important;
}

.red-color {
  background-color: red !important;
  color: white !important;
}

.page-link.active {
  font-weight: bold !important;
  border: 2px solid black !important;
}

@media (max-width: 600) {
  .QuizBody-col-2 {
    border: none !important;
  }
}
@media (max-width: 600) {
  .quizBody-col-3 {
    border-top: 1px solid rgb(151, 180, 227) !important;
  }
}
@media (min-width: 900px) {
  .QuizBody-col-2 {
    border-left: 1px solid rgb(151, 180, 227);
  }
}
