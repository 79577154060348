@import "./quiz_Dashboard/variables";

.QuizBoardLandingHeroSection {
  position: relative;
  overflow: hidden; /* Prevents overflow of the shape */
  
  .QuizBoardLandingHeroSection-A {
    position: relative;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1; /* Ensure text is above the SVG shape */
    padding-top: 100px; /* Adjust as needed to avoid text overlap with the top shape */
    padding-bottom: 100px;

    h1 {
      font-size: 2.5rem;
      font-weight: bolder;
    }

    h3 {
      font-size: 3rem;
      font-weight: bold;
      background: linear-gradient(80deg, #777474, #000);
      -webkit-background-clip: text;
      color: transparent;
    }

    p {
      font-size: 1.3rem;
      font-weight: 400;
      font-family: sans-serif;
      color :  #9B7EBD;
    }

    button {
      color: #ffffff; /* Replace with a color if $lightcolor is unavailable */
      background-color: #9B7EBD;
      cursor: pointer;
      font-weight: bold;
      transition: background-color 0.3s ease;
      
      &:hover {
        background-color: #5b4b6d ;
      }
    }
  }

  .custom-shape-divider-top-1731340048 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1731340048 svg {
    position: relative;
    display: block;
    width: calc(230% + 1.3px);
    height: 500px;
}

.custom-shape-divider-top-1731340048 .shape-fill {
    fill: $background-color;
}

}


// QuizBoardLandingFeaturesSection code 
.QuizBoardLandingFeaturesSection{
  h3{
    font-size: 3rem;
    font-weight: bold;
    background: linear-gradient(80deg, #777474, #000);
      -webkit-background-clip: text;
    color: transparent;
  }
  .QuizBoardLandingFeaturesSection-card{
    width: 330px;
    height: 280px;
    background-color: $background-color;
    // background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1), 0px 6px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
   &:hover {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2), 0px 12px 30px rgba(0, 0, 0, 0.1); 
  }
  span{
    font-size: 4.5rem;
    color: #9B7EBD ;
  }
  h5{
    // color: $greyDark;
    color:#3B1E54;
    font-size: 1.5rem;
    font-weight: 600;
  }
  p{
    color: $grey;
  }
  }
}

//QuizBoardLandingHowWork 
.QuizBoardLandingHowWork {
  h3{
    font-size: 3rem;
    font-weight: bold;
    background: linear-gradient(80deg, #777474, #000);
      -webkit-background-clip: text;
    color: transparent;
  }
  .vertical-pipeline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // margin: 20px auto;
    position: relative;

    .pipeline-step {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;
      position: relative;

      .icon-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 20px;

        .step-icon {
          width: 60px;
          height: 60px;
          background-color: #f3f3f3;
          border: 3px solid #9B7EBD;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          color: #9B7EBD;
          z-index: 1;
        }

        .pipeline-line {
          width: 2px;
          height: 100%;
          background-color: #9B7EBD;
          position: absolute;
          top: 60px; /* Aligns with the bottom of the step-icon */
          left: 50%; /* Centers the line */
          transform: translateX(-50%);
          z-index: 0;
        }
      }

      .content {
        h5 {
          margin: 0;
          font-size: 1.2rem;
          color: #333;
        }

        p {
          margin: 5px 0 0;
          font-size: 0.9rem;
          color: #666;
        }
      }
    }
  }

  .QuizBoardLandingHowWork-img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
}


//QuizBoardLandingFAQSection
.QuizBoardLandingFAQSection{
  h3{
    font-size: 3rem;
    font-weight: bold;
    background: linear-gradient(80deg, #777474, #000);
    -webkit-background-clip: text;
    color: transparent;
  }
  .QuizBoardLandingFAQSection-box{
   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
   &:hover {
    background-color: rgb(210, 194, 246);
    color: black;

    h5 {
      color: #3B1E54;
    }
    span{
      color: #3B1E54;
    }
  }
  }
}

//QuizBoardLandingPageFooter
.QuizBoardLandingPageFooter{
  .QuizBoardLandingPageFooter-A{
    background-color: $background-color;
    .QuizBoardLandingPageFooter-Col-2{
      .QuizBoardLandingPageFooter-Col-2-B{
      //  span{
      //   color:white;
      //   background-color: rgb(119, 37, 243);
      //  }
      }
    }
  }
  .QuizBoardLandingPageFooter-B{
    .icon-section{
      a{
        padding-top: 0px !important;
        border: 1px solid rgb(18, 18, 18);
        border-radius: 100%;
        padding-bottom: 8px;
        background-color:black ;
        color: white;
        transition: all ease-in-out 2s;
        &:hover {
          // transform: rotateY(90deg);
          transform: translateY(-10px); /* Moves 20px right and 10px down */
        }
      }
      
    }
  }
}