@import "_variables";
@import "QuestionEditMainSection.scss";

.questions-header{
  padding-top:15px;
  background-color: $background-color;
  border-bottom: 2px solid $border-color;
  span{
      color:grey;
  }
  a{
      color:$greyDark;
  }
  button{
      background-color: $lightcolor;
      border:1px solid blue;
      border-radius:6px;
      padding:4px 7px;
      margin-right:7px;
      color:blue;
  }
}
.question-page {
    min-height: 100vh;
    background-color: $background-color;
    .questions-list {
      span {
        color: grey;
      }
      a {
        color: $greyDark;
        text-decoration: none;
        &:hover {
          color: grey;
        }
      }
      .question-card {
        background-color: $lightcolor;
        border: 1px solid $border-color;
        border-radius: 2px;
        .qlevel-btn {
          border: 1px solid rgb(102, 240, 102);
          color: rgb(102, 240, 102);
          padding: 2px 12px;
          border-radius: 4px;
          background-color: $lightcolor;
        }
        .score-btn {
          border: 1px solid rgb(211, 208, 208);
          border-radius: 12px;
          background-color: $lightcolor;
          padding: 3px 7px;
        }
      }
    }
  }
.q-type-sidebar{
    span{
      color: $grey;
      padding:2px;
      font-size: 16px;
    }
  }
.question-view {
    background-color: $background-color;
    text-align: left;
    overflow: hidden;
    .question-view-header {
      background-color: $lightcolor;
      box-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.1); 
      border: none;
      z-index: 1;
      .buttons{
        text-align: right;
      }
      button {
        border: 1px solid $sidebarlinkcolor;
        border-radius: 5px;
        padding: 4px 15px;
        margin:10px;
      }
      .edit-que-btn {
        color: $sidebarlinkcolor;
        background-color: $lightcolor;
      }
      .try-que-btn {
        background-color: $sidebarlinkcolor;
        color: $lightcolor;
      }
    }
    .hero-section{
      background-color:$lightcolor;
      border-right: 1px solid $border-color;
      text-align: left;
      min-height:73vh ;
      margin-right: 15px;
      li{
        padding:4px;
      }
      .correct-field{
        background-color:rgba(132, 226, 132, 0.5);
        color:green;
        padding-left: 3px;
        width:100px;
        margin:5px;
      } }
    .side-bar {
      background-color:$lightcolor;
      text-align: left;
      border-left: 1px solid $border-color;
      min-height:73vh ;
      .level-btn {
        border: 1px solid rgb(79, 219, 79);
        color: rgb(79, 219, 79);
        border-radius: 4px;
        font-size: larger;
        background-color: $lightcolor;
      }
      p{
        font-size: 32px;
        font-weight: 500;
      }
      span{
        color:$grey;
      }
    }
  }
.question-edit {
    background-color: $background-color;
    .question-edit-header{
      background-color: $lightcolor;
      box-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.1);
      border-bottom:1px solid $border-color;
      z-index: 1;
    }
    .edit-footer{
      background-color: $lightcolor;
      border:1px solid $border-color;
      button{
        margin:3px 7px;
        padding: 3px 8px;
        font-size: 14px;
        border-radius: 5px;
      }
      .publish-btn{
        color:$lightcolor;
        background-color:$sidebarlinkcolor;
        border:1px solid $sidebarlinkcolor;
      }
      .discard-btn{
        border:1px solid #fafa4d;
        background-color:#fafa4d;
        color:$greyDark;
      }
    } 
  }

  // questions-header file code 

  @media (max-width:600px) {
    .questions-header{
      font-size: $font-size;
    }
    .Question-overview-A{
       display: flex;
       flex-direction: row-reverse;
    }
    .questionViewDrawer{
      width:100%;
    }
  }