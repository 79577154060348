@import "./quiz_Dashboard/variables";
.quiz-test-preview-header {
  nav {
    background-color: rgb(29, 29, 211);
    .button-preview {
      background-color: rgb(221, 170, 41);
      border: 1px solid rgb(221, 170, 41);
      border-radius: 10px;
      padding: 1px 8px;
    }
    .timer {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 22px;
    }
  }
  .details-div {
    background-color: rgb(226, 230, 231);
    font-size: 22px;
    i {
      color: rgb(75, 219, 75);
    }
  }
}
.quiz-test-preview-detail-Main {
  // background-color:$background-color;
  // color: #D7D7D7;
  .quiz-test-preview-detail {
    .info-card {
      border: 1px solid rgb(216, 213, 213);
      background-color: $background-color;
      // box-shadow: 1px 1px 4px rgb(216, 213, 213), -1px -1px 4px rgb(216, 213, 213);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      .highlight {
        background-color: rgba(255, 245, 220, 255);
        border-left: 5px solid rgba(244, 175, 37, 255);
        i {
          color: rgba(244, 175, 37, 255);
        }
      }
      a {
        text-decoration: none;
      }
      .start-test-btn {
        background-color: #4079da;
        // background-color: rgb(91, 91, 91);
        color: white;
        border: 1px solid rgb(167, 165, 165);
        border-radius: 4px;
        font-size: 20px;
        width: 150px;
      }
      .border-end-dark {
        border-right: 1px solid grey;
      }
      .scroll-bar {
        height: 15px;
        background-color: #2b3852b3;
      }
      .date-time-para {
        span {
          color: grey;
          b {
            color: rgb(43, 42, 42);
          }
        }
      }
    }
  }
}

.quiz-playground-body {
  min-height: 100vh; // Ensures it covers the full viewport height
  display: flex;
  flex-direction: column;
  background-color: linear-gradient(
    to bottom,
    #f8f9fa,
    #e9ecef
  ); // Change this to your preferred background color
}

.quiz-test-proctoring-setting {
  .proctoring-setting {
    border: 1px solid rgb(234, 234, 235);
    background-color: rgba(247, 248, 252, 255);
    border-radius: 10px;
    .danger-color {
      color: rgb(224, 89, 89);
    }
  }
}

.generating-report-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.8),
    rgba(200, 200, 200, 0.2)
  );
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  .generating-report-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    letter-spacing: 1px;
  }
}

// Animation for the loader
