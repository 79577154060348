@import "_variables";
.ant-drawer {
  .ant-drawer-body {
    padding: 0px;
  }
  .close-button {
    position: absolute;
    left: -36px;
    top: 62px;
    background-color:rgba(247,248,255,255);
    border: none;
  }
}
