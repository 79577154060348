@import '_variables';
@import "candidates_table.scss";

.quiz-test-header {
  border-bottom: 2px solid $border-color;
  li {
    font-size: 20px;
  }
  button {
    border:1px solid $button-color;
    background-color: $button-color;
    border-radius:5px;
    color:$lightcolor;
    max-height:80%;
    text-align: center;
  }
  a{
    text-decoration: none;
  }
}
.quiz-test-sidenav {
  padding: 10px;
  text-align: left;
  h5{
    padding-left: 10px;
  }
  ul{
    margin:0px;
    padding: 0px 0px 0px 12px; 
    li{
      color:grey;
      list-style-type:none;
      padding:8px 0px;
      cursor: grab;
      font-size: 15px;
    }
    .active-tab{
      width:100%;
      background-color:rgba(255,245,220,255);
      border-left:5px solid  rgba(244,175,37,255);
      padding-left: 5px;
    }
  }
}
.quiz-test-body {
  background-color: $background-color
}
.testTaken-section {
  .header-testtaken{
      a{
        text-decoration: none;
      }
    #accessSelect{
      border: none;
      background-color: $background-color;
      color: blue;
      &:hover{
        border: none;
        box-shadow: none;
      }
    }
  }
}
.invited-section , .candidate-feedback-section{
  text-align:left;
  a{
    text-decoration: none;
  }
  .invited-table{
    tr {
      border:2px solid $border-color;
    }
    tr td{
      background-color: $lightcolor;
      font-size: 14px;
    }
  }
  button{
    background-color:$sidebarlinkcolor;
    color:$lightcolor;
    border:1px solid $sidebarlinkcolor;
    border-radius: 2px;
    font-size: 16px;
  }
}
.test-analytics-section{
  min-height: 100vh;
  text-align: left;
 
  .reliability-section ,.candidate-funnel ,.metric-performnace,.candidate-score{
    background-color: $lightcolor;
    border-radius: 5px;
    border:2px solid $border-color;
    p , span{
      color: rgb(114, 112, 112);
      padding:2px;
      margin: 0px;
    }
    .rise{
      color:rgb(90, 221, 90)
    }
    .drop{
      color:red;
    }
    .detail{
      color:$greyDark;
    }
    .reliability-score{
      border:2px solid $border-color;
      border-radius: 20px;
      max-width:22%;
      padding:2px 7px;
      .score{
        color:rgb(50, 199, 50);
        font-weight: 500;
      }
    }
  }
}
.question-analytics{
  h4{
    color:rgb(75, 75, 224);
  }
  .line-v{
    display: block;
    height:3px;
    background-color: $border-color;
  }
  // .QuestionAnalyticsRowCard{
  //   overflow: hidden;
  // }
  .question-analysis-card{
    background-color: $lightcolor;
    font-size: 15px;
    border:1px solid $border-color;
    border-radius: 5px;
    .ques{
      font-weight: 500;
    }
    .line-h{
      border:1px solid $border-color;
      display:flex;
    }
    .chart-block{
      border-left:2px solid $border-color;
    }
  }
}
.quiz-test-overview {
  margin: auto;
  text-align: left;
  a {
    text-decoration: none;
  }
  .side-nav-section {
    background-color: $lightcolor;
  }
  .advanced-setting {
    border: 1px solid $border-color;
    background-color: $lightcolor;
    border-radius: 5px;
    .bi-check-circle {
      color: green;
    }
  }
  .setting-section,
  .other-section,
  .test-description,
  .test-instructions,
  .faq-section{
    border: 1px solid $border-color;
    background-color: $lightcolor;
    border-radius: 5px;
    p {
      color:grey;
    }
  }
  .other-section{
    border: 1px solid $border-color;
    background-color: $lightcolor;
    border-radius: 5px;
    p{
      color:$greyDark;
      margin:0px
    }
    input{
        border:none;
        padding:0px;
        color:grey;
      }
  }
  .head-test-instruction{
    button{
      text-decoration: none;
      border:none
    }
  }
  .select-dashboard{
    background-color:$background-color;
    border:none;
    justify-content: center;
    &:hover{
      border:none;
      box-shadow: none;
    }
  }
  .info-section{
    background-color: rgba(234,243,254,255);
    border-left:3px solid rgba(71,142,233,255);
  }
}

.email-tab-section{
  text-align: left;
  .select-template{
   font-size: 18px;
   font-weight: 600;
   margin-bottom: 12px;
  }
  select{
    width:20%;
    &:hover{
      box-shadow: none;
    }
  }
  .form-section{
    border: 1px solid $border-color;
    background-color: $lightcolor;
    border-radius: 6px;
    font-size: 18px;
  }
  .note-section{
    font-size: 15px;
  }
}

// quiz_test_dashboard Media Query
// drawer code start

@media (min-width:600px){
  .QuizTestSideNavbarDrawer{
    display: none;
  }
}
@media  (max-width:600px) {
  .quiztestsideNAvbar{
    display: none;
  }
}
@media (min-width:600px) {
  .side_navbar-drawer{
    display:none ;
  }
}

// Drawer code end 

@media (max-width:600px) {
  .Header_Menu{
   display: none;
   text-align: center;
  }
}
@media (min-width:600px) {
  .Menu_Icon{
   display: none;
  }
}
// QuizTestHEader 
$font-size:13px;

@media (max-width:600px) {
  .quiz-test-header{
    display:flex;
    flex-direction: row;
    font-size:$font-size;

  .quiz-test-header-child-A{
     flex-direction: column;
     font-size:$font-size;

     button{
     text-align: center;
     font-size:$font-size;
     min-width: fit-content;
     padding: 2px 0px;
     }
    }
  }
}
// setting section code 
// other setting section 


@media (max-width:600px) {

  .OtherDetailsSection{
    padding-top: 3.5vh !important;
  }
}

@media (max-width:600px) {
.email-tab-section{
  font-size:  $font-size;
  .form-section{
    display: flex;
    font-size:  $font-size;
    flex-direction: column;
    .note-section{
      padding-top: 0px !important;
      justify-content: space-evenly;
    }
  }
  select{
    width:50%;
    &:hover{
      box-shadow: none;
    }
  }
}
}

@media (max-width:600px) {
   .reliability-score{
   min-width: 74%;
  //  font-size: $font-size;
  }
  .metric-performnace{
    // font-size: $font-size;
  }
  .analytics-header{
     // font-size: $font-size;
  }
   .question-card-response{
    display: flex;
    flex-direction: column;
   }
 
  .candidate-feedback{
    display: flex;
    flex-direction: column;
    button{
      width: 60vw;
    }
  }
}
@media (max-width:600px) {
  .header-testtaken{
   .Header-Test-Taken-Child{
    display: flex;
    flex-direction: column;
   }
  }
}
@media (max-width:600px) {
  .other-section{
   
  }
  
}