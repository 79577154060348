.main-section{
    .question-edit-form {
      min-height: 100vh;
      background-color: $lightcolor;
      input[type="text"] {
        border:1px solid $border-color;
        border-radius:4px;
        width:100%;
        padding: 10px;
      }
      .cross-button{
        border:none;
        background-color:$lightcolor;
      }
    }
    .edit-sidebar{
      background-color: $lightcolor;
      min-height: 106vh;
      h6{
        padding:8px 0px;
      }
      .score{
        width:30%;
        border-radius:3px;
        border:1px solid $grey;
        padding:3px 10px;
        font-size: larger;
        margin:2px;
      }
      .tag-field{
        width:100%;
        border-radius:3px;
        border:1px solid $grey;
        padding:4px 8px;
      }
    }
  }