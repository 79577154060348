@import "_variables";

$background-color: #f8f9fa;
$button-color: #6666d3;
$border-color: #dee2e6;
$lightcolor: #ffffff;
$greyDark: #343a40;
$hover-color: darken(#6666d3, 10%);
$shadow-color: rgba(0, 0, 0, 0.1);

.quiz-list {
  text-align: left;
  .sidebar {
    margin-top: 20px;
    position: fixed;
    a,
    Link {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }

    p {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      font-weight: 600;
      color: $greyDark;
    }
    ul {
      list-style: circle;
      li {
        margin-bottom: 0.5rem;
        font-size: 1rem;
        color: #555;
      }
    }

    .line-break {
      border: none;
      height: 0.5px;
      margin-bottom: 1rem;
      background-color: rgba(0, 0, 255, 0.5);
    }

    select,
    input[type="checkbox"] {
      margin-bottom: 0.5rem;
      width: 100%;
    }

    select {
      padding: 0.375rem;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      margin-bottom: 0.5rem;
      background-color: #fff;
    }

    input[type="checkbox"] {
      width: auto;
      margin-right: 0.5rem;
    }
  }
}

.quiz-list-body {
  min-height: 100vh;
  background-color: $background-color;
  padding: 2rem 3rem;

  .section-container {
    background: $lightcolor;
    border-radius: 8px;
    box-shadow: 0 2px 8px $shadow-color;
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 4px 12px rgba($button-color, 0.15);
    }
  }

  .section-header {
    padding: 1rem;
    border-bottom: 1px solid $border-color;

    .toggle-button {
      cursor: pointer;
      color: $button-color;
      transition: transform 0.2s ease;

      &:hover {
        transform: translateY(-2px);
      }

      i {
        font-size: 1.2rem;
      }
    }

    h5 {
      margin: 0;
      color: $greyDark;
      font-weight: 600;
    }
  }

  .back-link {
    color: $button-color;
    transition: color 0.2s ease;

    &:hover {
      color: $hover-color;
    }

    i {
      transition: transform 0.2s ease;
    }

    &:hover i {
      transform: translateX(-4px);
    }
  }

  .collapse {
    transition: all 0.3s ease-in-out;
  }

  .card-container {
    padding: 1rem;

    .quiz-card-wrapper {
      width: 100%;
      margin-bottom: 1rem;
      transition: transform 0.2s ease;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  input {
    padding: 3px;
    width: 80%;
    border: 1px solid $border-color;
    border-radius: 2px;
  }

  .search-icon {
    background-color: $button-color;
    padding: 4px 15px;
    width: 100%;
    color: $lightcolor;
    border: 1px solid $border-color;
    border-radius: 3px;
  }

  button {
    background-color: $button-color;
    padding: 4px;
    width: 60%;
    color: $lightcolor;
    border: 1px solid $border-color;
    border-radius: 6px;
    font-size: 18px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: $hover-color;
    }
  }

  a,
  Link {
    text-decoration: none;
    h6 {
      color: $greyDark;
      transition: color 0.2s ease;
      &:hover {
        text-decoration: underline;
        color: $button-color;
      }
    }
  }
  .quiz-card {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    transition: 0.3s;
  }

  .quiz-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .quiz-title {
    font-size: 18px;
    font-weight: 600;
    color: #673de6; // Your primary color
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .status-badge {
    padding: 6px 12px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;

    &.passed {
      background: #e6f4ea;
      color: #28a745;
    }

    &.failed {
      background: #f8d7da;
      color: #dc3545;
    }
  }

  .quiz-card-details {
    margin: 10px 0;
    display: flex;
    gap: 20px;
    font-size: 14px;
    color: #555;
  }

  .quiz-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }

  .btn-link {
    font-size: 14px;
    font-weight: 500;
    color: #673de6;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .quiz-card-actions {
    display: flex;
    gap: 10px;
  }

  .btn-action {
    font-size: 14px;
    font-weight: 500;
    color: #555;
    text-decoration: none;
    display: flex;
    align-items: center;

    i {
      margin-right: 5px;
    }

    &:hover {
      color: #673de6;
    }
  }

  .quiz-card-button {
    text-align: center;
    margin-top: 15px;
  }

  .primary-btn {
    background-color: #673de6 !important;
    text-decoration: none !important;
    cursor: pointer;
    border: none;
    font-size: 16px;
    padding: 8px 20px;
    border-radius: 5px;
    color: #fff;
    transition: 0.3s;
    width: fit-content;

    &:hover {
      background-color: #5b2dd1 !important;
    }
  }
}
//  responsive
@media (max-width: 600px) {
  .SideNavbar {
    display: none;
  }
}

@media (max-width: 600px) {
  .headerMenu {
    display: none;
  }
}
@media (min-width: 600px) {
  .menuIcon {
    display: none;
  }
}
@media (max-width: 600px) {
  .sidebar {
    margin-top: none !important;
    position: fixed;
    a,
    Link {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }

    p {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      font-weight: 600;
      color: $greyDark;
    }
    ul {
      list-style: circle;
      li {
        margin-bottom: 0.5rem;
        font-size: 1rem;
        color: #555;
      }
    }

    .line-break {
      border: none;
      height: 0.5px;
      margin-bottom: 1rem;
      background-color: rgba(0, 0, 255, 0.5);
    }

    select,
    input[type="checkbox"] {
      margin-bottom: 0.5rem;
      width: 100%;
    }

    select {
      padding: 0.375rem;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      margin-bottom: 0.5rem;
      background-color: #fff;
    }

    input[type="checkbox"] {
      width: auto;
      margin-right: 0.5rem;
    }
  }
}

// quizList Dashboard

@media (max-width: 600px) {
  $font-size: small;
  $f-s: 15px;

  .quiz-list-body {
    padding: 0px 0px !important;
    min-height: 100vh;
    margin-top: 20px !important;
    background-color: $background-color;
    .quiz-list-body-child-A {
      // display:flex;
      align-items: center !important;
      justify-content: center !important;
    }
    input {
      padding: 3px;
      width: 70%;
      width: 85%;
      border: 1px solid $border-color;
      border-radius: 2px 2px 2px 2px;
      // font-size:  $f-s!important;
    }
    .search-icon {
      background-color: $button-color;
      padding: 4px 10px;
      color: $lightcolor;
      border: 1px solid $border-color;
      border-radius: 3px 3px 3px 3px;
      // font-size:  $f-s!important;
      font-size: 18px;
    }
    button {
      background-color: $button-color;
      // padding: 4px 2px;
      color: $lightcolor;
      border: 1px solid $border-color;
      border-radius: 6px;
      font-size: $f-s !important;
    }
    a,
    Link {
      text-decoration: none;
      h6 {
        color: $greyDark;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .quiz-list-body-child {
      display: flex !important;
      flex-direction: column-reverse;
      row-gap: 10px;
      .bty {
        text-align: right;
      }
    }
  }
}
@media (max-width: 600px) {
  .test-card {
    display: flex;
    flex-direction: column;
  }
}
