@import 'bootstrap-icons/font/bootstrap-icons.css';
$border-color: rgb(234, 234, 235);
$background-color:rgba(247,248,252,255); 
$greyDark:black;
$lightcolor: white;
$background-color:rgba(247,248,252,255); 
$button-color:rgb(102, 102, 209);
$sidebarlinkcolor:rgb(62, 62, 224);
$grey:grey;



// For Responsive
$font-size:12px;

