// @import "_variables";

a {
  cursor: pointer;
  .pagination-selected {
  }
}

@media (max-width: 600px) {
  .Quiz-section {
    row-gap: 5px;
  }
}
@media (max-width: 600px) {
  .question-change {
    display: flex;
    flex-direction: column;
  }
}
