.login {
  .login-card {
    padding: 10px;
  }

  .login-section {
    background-color: rgb(243, 243, 243);
  }

  .google-button {
    display: flex;
    flex-direction: row;
    background-color: rgb(66, 133, 244);
    width: 100%;
    box-sizing: content-box;
    cursor: pointer;
    padding: 0px 2px 0px 0px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(66, 133, 244);
    border-image: initial;
    border-radius: 2px;

    .google-login-string {
      color: rgb(255, 255, 255);
      font-size: 14px;
      font-weight: bold;
      font-family: "Akzidenz", "Helvetica Neue", Helvetica, Arial, sans-serif;
      line-height: 40px;
      height: 40px;
    }
  }

  .or-line {
    position: relative;
    text-align: center;
    width: 100%;
    margin: 20px 0px;
  }

  .login-text {
    font-family: "Akzidenz", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: rgb(33, 49, 60);
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0px;
    margin: unset;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  .login-button {
    color: lightgray;
    position: relative;
    display: inline-flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    font-family: "Akzidenz", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 14px;
    color: rgb(137, 152, 155);
    background-color: rgb(231, 238, 236);
    border-radius: 5px;
    transition: all 120ms ease 0s;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    border-color: rgb(184, 196, 194);
  }
  .signup-string {
    font-size: 12px;
    color: rgb(61, 79, 88);
    font-weight: bold;
    line-height: 16px;
    display: inline;
    text-align: start;
  }
}

@media (max-width: 767px) {
  .img-section {
   display: none;
  }
}
